<template>
  <b-card class="map-airport">
    <b-card-title class="text-center">
      {{ $t('provider.location') }}
    </b-card-title>
    <GmapMap
      ref="airportMap"
      style="width: 100%; height: 500px;"
      :center="{lat: 43.46, lng: -1.53}"
      :zoom="7"
    >
      <GmapMarker
        v-for="(marker, index) in location"
        :key="index"
        :position="marker.position"
        :clickable="!!marker.id"
        @click="openMarker(marker.id)"
      >
        <gmap-info-window
          :opened="openedMarkerID === marker.id"
          @closeclick="openMarker(null); markerInfo = null"
        >
          <h5>{{ marker.airport }}</h5>
          <div>
            {{ marker.info }}
          </div>
        </gmap-info-window>
      </GmapMarker>
    </GmapMap>
  </b-card>
</template>
<script>

export default {
  name: 'ProviderProfileMap',

  props: {
    location: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moreInfo: false,
      openedMarkerID: null,
      markerInfo: null,
    }
  },
  mounted() {
  },
  methods: {
    currentInfoMarker(marker) {
      this.markerInfo = marker
      setTimeout(() => {
        this.scrollIntoSlots()
      }, 100)
    },
    scrollIntoSlots() {
      const el = document.getElementById('airportSlot')
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
        })
      }
    },
    openMarker(id) {
      this.openedMarkerID = id
    },
    setPlace(place) {
      this.place = place
    },
  },
}
</script>
<style scoped>
.map-airport {
  position: relative;
}
</style>
