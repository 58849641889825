<template>
  <!-- NOTE: text/value-field = key/value by default if 'options' is an array -->
  <b-form-group
    :label="label + `${required ? ' *' : ''}`"
    :label-for="id"
  >
    <b-form-checkbox-group
      :value="value"
      :required="required"
      :value-field="valueField"
      :text-field="textField"
      :checked="value"
      @change="$emit('change', $event)"
    >
      <b-row>
        <b-col
          v-for="option in options"
          :key="option.text"
          class="pb-1"
          cols="12"
          md="4"
        >
          <b-form-checkbox
            :v-for="option in value"
            :value="option.value"
            :disabled="disabled"
          >
            {{ enumTranslate(enumClass, option.value, true) }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form-checkbox-group>
  </b-form-group>
</template>

<script>
import { mapActions } from 'vuex'
import EnumTranslationHelper from '@/helpers/enumTranslation'

export default {
  name: 'EnumCheckbox',

  mixins: [EnumTranslationHelper],
  props: {
    valueField: {
      type: String,
      default: 'value',
    },
    textField: {
      type: String,
      default: 'text',
    },
    value: {
      type: Array,
      default: () => [],
    },
    enumClass: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: 'enumCheckox',
    },
    label: {
      type: String,
      default: '',
    },
    transformText: {
      type: Function,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    options() {
      return this.$store.state.enum[this.enumClass]
    },
  },
  mounted() {
    this.fetchEnum2(this.enumClass)
  },
  methods: {
    ...mapActions('enum', ['fetchEnum2']),
  },
}
</script>
