<template>
  <section>
    <div v-if="isHelicopter">
      <b-button
        class="mb-1"
        :to="{ name: 'trip-view', params: { trip_id: $route.params.trip_id } }"
      >
        {{ $t('provider.back_to_timeline') }}
      </b-button>
      <b-button
        class="ml-1 mb-1"
        :to="{ name: 'TimelineStepTransferHelicopterOrganizations', params: pick($route.params, 'trip_id', 'step_id', 'event_id') }"
      >
        {{ $t('provider.back_to_catalogue') }}
      </b-button>
    </div>

    <!--TRIP RESUME-->
    <b-overlay :show="!tripData">
      <trip-view :trip-data="tripData" />
    </b-overlay>

    <!--ACTION-->
    <b-row v-if="!isHelicopter">
      <b-col class="d-flex justify-content-end mb-1" cols="12">
        <b-button
          variant="primary"
          class="mr-1"
          :to="{
            name: 'shop-checkout',
            params: {
              trip_id: this.$route.params.trip_id,
              step_id: this.$route.params.step_id,
              event_id: this.$route.params.event_id,
              airport_id: this.$route.params.airport_id,
            },
          }"
        >
          <feather-icon class="mr-50" icon="ShoppingCartIcon" />
          {{ $t('trip.shop.go_to_cart') }}
        </b-button>
        <b-button
          v-ripple.400
          :to="{
            name: 'provider-list',
            params: {
              trip_id: this.$route.params.trip_id,
              event_id: this.$route.params.event_id,
              airport_id: this.$route.params.airport_id,
            },
          }"
          variant="primary"
        >
          <font-awesome-icon class="mr-50" icon="exchange-alt" />
          {{ $t('provider.change_provider') }}
        </b-button>
      </b-col>
    </b-row>

    <!--PROVIDER COMPONENTS-->
    <section>
      <provider-profile-header :provider="provider" :current-nav="currentNav" :is-helicopter="isHelicopter" @current-nav="handleCurrentNav($event)" />

      <!--SERVICES-->
      <section v-if="!isHelicopter" v-show="currentNav === 'services'">
        <MandatoryPackage />
        <section>
          <shop-flight v-if="!tripStep.engineType" :provider="provider" />
          <shop-transfer v-else />
        </section>
      </section>
      <!--HANGAR-->
      <section v-if="!isHelicopter" v-show="currentNav === 'hangar'">
        <section>
          <hangar-list v-if="!tripStep.engineType" :provider="provider" />
        </section>
      </section>
      <!--FUEL-->
      <section v-if="!isHelicopter" v-show="currentNav === 'fuel'">
        <section>
          <fuel-list v-if="!tripStep.engineType" :provider="provider" />
        </section>
      </section>
      <!--DESCRIPTION-->
      <section v-if="currentNav === 'description'">
        <b-row v-if="provider.description">
          <b-col cols="12">
            <b-card>
              <b-card-text>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="provider.description" />
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>

        <b-row class="match-height">
          <b-col lg="4" sm="12">
            <ProviderProfileInformations :provider="provider" />
          </b-col>

          <b-col lg="4" sm="12">
            <ProviderProfileWorkingHours v-if="provider" :provider="provider" />
          </b-col>

          <b-col lg="4" sm="12">
            <ProviderProfileMap :location="[{ position: { lat: provider.defaultAddress.latitude, lng: provider.defaultAddress.longitude, } }]" />
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col lg="12" sm="12">
            <ProviderProfileFacilities :provider="provider" />
          </b-col>
        </b-row>
      </section>
      <!--HELICOPTER-->
      <section v-if="isHelicopter" v-show="currentNav === 'helicopters'">
        <section>
          <b-row>
            <b-col md="9" class="d-flex align-items-center">
              <h1>{{ $t('provider.helicopters_fleet') }}</h1>
            </b-col>
            <b-col md="3">
              <b-form-group :label="$t('provider.bases')" label-for="bases">
                <v-select
                  v-model="selectedBase"
                  input-id="bases"
                  :options="basesOptions"
                  :reduce="option => option.id"
                  :get-option-label="option => option.cityBase"
                  :clearable="false"
                  @option:selected="APIFetchTripStepEventOnQuoteHelicopter()"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-overlay :show="servicesLoader" :spinner-small="false">
            <b-row>
              <b-col v-for="service in services" :key="service.id" md="4">
                <b-card :img-alt="`service-logo-${service.id}`" :img-src="service.mainVariant.files.length ? service.mainVariant.files[0].fileUrls[300] : require('@/assets/images/logo/logo.png')" class="border">
                  <b-col>
                    <div class="m-0 pr-0 d-flex flex-wrap align-items-start">
                      <div class="col-12">
                        <div class="d-flex flex-column align-items-end">
                          <span v-if="service.departureName && service.arrivalName">{{ service.departureName }} > {{ service.arrivalName }}</span>
                          <b-link v-if="(service.departureDepositZones && service.departureDepositZones.length) || (service.arrivalDepositZones && service.arrivalDepositZones.length)" @click="selectedService = service">Consult deposit zones</b-link>
                        </div>
                        <div>
                          <b-link
                              class="h3"
                              variant="primary"
                              :to="{
                                name: 'shop-service-helicopter-details',
                                params: {
                                  trip_id: $route.params.trip_id,
                                  step_id: $route.params.step_id,
                                  event_id: $route.params.event_id,
                                  required_organization_id: provider.id,
                                  destination_id: service.mainVariant.id,
                                  type: 'on-quote'
                                } }"
                          >
                            {{ service.name | trans }}
                          </b-link>
                          <b-col class="pr-0">
                            <div>
                              <h5 class="font-weight-bold">{{ $t('service.technical_informations.title') }}</h5>
                              <ul>
                                <li v-for="attribute in service.mainVariant.variantAttributes" :key="attribute.id">
                                  {{ attribute.attribute.name | trans }} :
                                  <span v-if="attribute.inputValues">{{ attribute.inputValues | trans }}</span>
                                  <span v-if="attribute.selectValue">{{ attribute.selectValue.name | trans }}</span>
                                  <ul v-if="attribute.choiceValues">
                                    <li v-for="choice in attribute.choiceValues" :key="choice.id">
                                      {{ choice.name | trans }}
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </b-col>
                        </div>
                      </div>
                      <b-col>
                        <b-button
                            block
                            class="btn-cart"
                            variant="primary"
                            :to="{
                                name: 'shop-service-helicopter-details',
                                params: {
                                  trip_id: $route.params.trip_id,
                                  step_id: $route.params.step_id,
                                  event_id: $route.params.event_id,
                                  required_organization_id: provider.id,
                                  destination_id: service.mainVariant.id,
                                  type: 'on-quote'
                                } }"
                        >
                          <span>{{ $t('trip.shop.view_details') }}</span>
                        </b-button>
                      </b-col>
                    </div>
                  </b-col>
                </b-card>
              </b-col>
            </b-row>
            <!--PAGINATION-->
            <div class="mx-2 mb-2">
              <app-data-table-bottom
                :page="page"
                :per-page="12"
                :total-count="servicesMeta.totalItems"
                table-name="services"
                @pagination="APIFetchTripStepEventOnQuoteHelicopter($event)"
              />
            </div>
          </b-overlay>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import { omit, pick } from 'lodash'

import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import TripView from '@/views/trip/trip-view/TripView.vue'
import ShopFlight from '@/views/shop/shop/flight/ShopList.vue'
import ShopTransfer from '@/views/shop/shop/transfer/ShopTransfer.vue'
import HangarList from '@/views/provider/hangar/HangarList.vue'
import FuelList from '@/views/provider/fuel/FuelList.vue'
import ProviderProfileHeader from '@/views/provider/profile/ProviderProfileHeader.vue'
import ProviderProfileInformations from '@/views/provider/profile/ProviderProfileInformations.vue'
import ProviderProfileMap from '@/views/provider/profile/ProviderProfileMap.vue'
import ProviderProfileFacilities from '@/views/provider/profile/ProviderProfileFacilities.vue'
import MandatoryPackage from '@/views/provider/mandatoryPackage/MandatoryPackage.vue'
import { fetchOrganizationRequest } from '@/request/globalApi/requests/organizationRequests'
import ProviderProfileWorkingHours from '@/views/provider/profile/ProviderProfileWorkingHours.vue'
import {
  fetchTripStepEventOnQuoteHelicopterRequest,
  fetchTripStepEventOrganizationBasesRequest,
} from '@/request/globalApi/requests/tripStepEventRequests'

export default {
  name: 'ProviderDetails',

  components: {
    AppDataTableBottom,
    ProviderProfileWorkingHours,
    TripView,
    MandatoryPackage,
    ShopFlight,
    ShopTransfer,
    HangarList,
    FuelList,
    ProviderProfileHeader,
    ProviderProfileInformations,
    ProviderProfileMap,
    ProviderProfileFacilities,
  },

  data() {
    return {
      currentNav: 'services',
      tripData: {},
      provider: null,
      servicesLoader: true,
      services: [],
      page: 1,
      servicesMeta: {
        firstPage: 1,
        lastPage: 1,
        nextPage: null,
        previousPage: null,
        totalItems: 0,
      },
      basesOptions: [{ id: null, cityBase: this.$t('provider.all_bases') }],
      selectedBase: null,
    }
  },
  computed: {
    isHelicopter() {
      return !this.$route.params.airport_id
    },
    tripStep() {
      if (!this.tripData.tripSteps) return {}
      const stepId = Number(this.$route.params.step_id)
      return this.tripData.tripSteps.find(item => item.id === stepId)
    },
  },
  created() {
    if (this.isHelicopter) this.currentNav = 'helicopters'
  },
  mounted() {
    this.APIFetchOrganization()
    this.APIFetchTrip()
    if (this.isHelicopter) {
      this.APIFetchTripStepEventOnQuoteHelicopter()
      this.APIFetchOrganizationBases()
    }
  },
  methods: {
    pick,
    async APIFetchTripStepEventOnQuoteHelicopter(page = null) {
      this.page = page || 1
      this.servicesLoader = true
      fetchTripStepEventOnQuoteHelicopterRequest(this.$route.params.event_id, this.$route.params.provider_id, { baseId: this.selectedBase, page }).then(({ data }) => {
        this.services = data.services
        this.servicesMeta = omit(data, 'services')
      }).finally(() => {
        this.servicesLoader = false
      })
    },
    async APIFetchTrip() {
      const tripId = Number(this.$route.params.trip_id)
      try {
        this.tripData = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: false })
      } catch (error) {
        console.warn(error)
      }
    },
    APIFetchOrganization() {
      const organizationId = Number(this.$route.params.provider_id)
      fetchOrganizationRequest(organizationId).then(r => {
        this.provider = r.data.organization
        this.provider.id = organizationId
      })
    },
    APIFetchOrganizationBases() {
      const organizationId = Number(this.$route.params.provider_id)
      const eventId = Number(this.$route.params.event_id)
      fetchTripStepEventOrganizationBasesRequest(eventId, organizationId).then(({ data }) => {
        this.basesOptions = this.basesOptions.concat(data.bases)
      })
    },
    handleCurrentNav(nav) {
      this.currentNav = nav
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
</style>
