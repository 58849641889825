<template>
  <b-card id="provider-profile-facilities" class="border">
    <b-card-title>
      {{ $t('provider.facilities_assets') }}
    </b-card-title>
    <h6>{{ $t('common.aircraft') }}</h6>

    <enum-checkbox
      id="aircraftFacilities"
      :value="aircraftFacilities"
      enum-class="AircraftFacilityValue"
      text="text"
      disabled
    />

    <h6>{{ $t('common.passenger') }}</h6>
    <enum-checkbox
      id="passengerFacilities"
      v-model="passengerFacilities"
      enum-class="passengerFacilityValue"
      text="text"
      disabled
    />

    <h6>{{ $t('common.crew') }}</h6>
    <enum-checkbox
      id="crewFacilities"
      v-model="crewFacilities"
      enum-class="CrewFacilityValue"
      text="text"
      disabled
    />
  </b-card>
</template>

<script>
import EnumCheckbox from '@/components/EnumCheckbox.vue'

export default {
  name: 'ProviderProfileFacilities',
  components: {
    EnumCheckbox,
  },
  props: {
    provider: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      aircraftFacilities: null,
      crewFacilities: null,
      passengerFacilities: null,
    }
  },
  mounted() {
    this.aircraftFacilities = this.provider.aircraftFacilities.map(el => el.aircraftFacilityValue)
    this.crewFacilities = this.provider.crewFacilities.map(el => el.crewFacilityValue)
    this.passengerFacilities = this.provider.passengerFacilities.map(el => el.passengerFacilityValue)
  },
}
</script>
<style lang="scss">
  #provider-profile-facilities {
    .custom-control-label {
      color: #5e5873;
    }
    .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
      background-color: rgba(220, 193, 129, 1);
    }
  }
</style>
