<template>
  <section class="row">
    <div class="ecommerce-application match-height col-12" style="height: inherit">
      <!-- ECommerce Header -->
      <section id="ecommerce-header">
        <div class="row">
          <div class="col-sm-12">
            <div class="ecommerce-header-items">
              <div class="result-toggler">
                <feather-icon class="d-block d-lg-none" icon="MenuIcon" size="21" />
              </div>
              <div v-if="false" class="view-options d-flex">
                <!-- Item View Radio Button Group  -->
                <b-form-radio-group v-model="itemView" button-variant="outline-primary" buttons class="ml-1 list item-view-radio-group" size="sm">
                  <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                    <feather-icon :icon="option.icon" size="18" />
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
        </div>
      </section>

      <b-overlay class="mt-2" :show="showLoadingSearch" spinner-medium>
        <fuel-search
          :loading="tripStepLoading"
          :trip-step="tripStep"
          :trip-step-event="tripStepEvent"
          @show-loading-search="val => (showLoadingSearch = val)"
          @fuel-type="val => (params.fuelType = val)"
          @fuel-type-name="val => (params.fuelTypeName = val)"
          @fuel-volume="val => (params.fuelVolume = val)"
          @click:search-fuel="searchFuel()"
        />
      </b-overlay>

      <b-overlay :show="showLoading" spinner-medium>
        <!--TABLE FUEL-->
        <b-row>
          <b-col md="12">
            <fuel-provider :trip-data="tripData" :trip-step="tripStep" :trip-step-event="tripStepEvent" :provider-list="providerList" :fuel-volume="params.fuelVolume" :fuel-type-name="params.fuelTypeName" />
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </section>
</template>

<script>
import number from '@/mixins/number'

import FuelProvider from '@/views/provider/fuel/FuelProvider.vue'
import FuelSearch from '@/views/provider/fuel/FuelSearch.vue'
import { fetchFuelVariantPriceRequest } from '@/request/globalApi/requests/apiRequests'

export default {
  name: 'FuelList',

  components: {
    FuelProvider,
    FuelSearch,
  },
  mixins: [number],
  props: {
    provider: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tripData: {},
      tripStep: null,
      tripStepEvent: null,
      tripLeg: null,
      legType: null,
      tripStepLoading: true,
      showLoadingSearch: true,
      providerList: null,
      showLoading: false,
      params: {
        fuelType: '',
        fuelVolume: 0,
      },
    }
  },
  mounted() {
    this.APIFetchTrip()
  },
  methods: {
    async APIFetchTrip() {
      const tripId = Number(this.$route.params.trip_id)

      const trip = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: false })

      if (trip) {
        this.tripData = trip

        trip.tripSteps.forEach(tripStep => {
          if (tripStep.flightDeparture && tripStep.flightArrival) {
            if (this.getCurrentTripStep(tripStep.flightDeparture)) {
              this.tripStep = tripStep
              this.tripStepEvent = tripStep.flightDeparture
            } else {
              this.getCurrentTripStep(tripStep.flightArrival)
              this.tripStep = tripStep
              this.tripStepEvent = tripStep.flightArrival
            }
          }
        })
      }
      this.tripStepLoading = false
    },
    getCurrentTripStep(leg) {
      let currentTripStep = null
      Object.entries(leg).forEach(([key, val]) => {
        if (key === 'id' && val === parseInt(this.$route.params.event_id, 10)) {
          currentTripStep = leg
          this.tripStep = currentTripStep
          return true
        }
        return false
      })
    },
    searchFuel() {
      this.showLoading = true

      this.APIFetchFuel()
    },
    APIFetchFuel() {
      this.organizationsIds = []
      this.products = []

      fetchFuelVariantPriceRequest(this.tripStep.id, this.params)
        .then(response => {
          const { fuelVariantPrices } = response.data
          this.providerList = fuelVariantPrices

          setTimeout(() => {
            this.showLoading = false
          }, 500)
        })
        .catch(() => {
          this.products = []
        })
    },
  },
}
</script>

<style scoped></style>
