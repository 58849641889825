<template>
  <b-sidebar id="sidebar-contact" :visible="isContactSidebarActive" @change="val => $emit('update:is-contact-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('common.message') }}
        </h5>

        <feather-icon class="mr-1 ml-auto cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- RESUME -->

      <b-card-body>
        <!--SUBJECT-->
        <validation-observer ref="contactRules" tag="form">

          <b-form-group class="mt-1" :label="$t('common.message')" label-for="serviceName">
            <validation-provider #default="{ errors }" rules="required" :name="$t('common.message')">
              <b-form-textarea id="textarea" v-model="content" rows="3" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button type="submit" @click.prevent="validationForm">
            <font-awesome-icon icon="paper-plane" class="mr-50" />
            {{ $t('action.send') }}
          </b-button>
        </validation-observer>
      </b-card-body>
    </template>
  </b-sidebar>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import { patchMessageChatRequest } from '@/request/globalApi/requests/chatRequests'

export default {
  name: 'SidebarContactProvider',

  model: {
    prop: 'isContactSidebarActive',
    event: 'update:is-contact-sidebar-active',
  },
  props: {
    isContactSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      content: '',
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
  },
  methods: {
    validationForm() {
      this.$refs.contactRules.validate().then(success => {
        if (success) {
          patchMessageChatRequest(this.content, this.$route.params.provider_id).then(r => {
            if (r) {
              this.isContactSidebarActive = false
            }
          })
        }
      })
    },
  },
}
</script>
