<template>
  <b-card>
    <b-card-title>
      {{ $t('provider.working_hours') }}
    </b-card-title>
    <b-row
      class="d-flex align-items-center font-weight-bold bg-light-primary border-light rounded"
    >
      <b-col
        cols="3"
        class="mr-1 py-1 bg-light-primary"
      >
        {{ $t('common.day') }}
      </b-col>
      <b-col
        cols="4"
        class="d-flex align-items-center border-right-light"
      >
        {{ $t('common.morning') }}
      </b-col>
      <b-col
        cols="4"
        class="d-flex align-items-center border-right-light"
      >
        {{ $t('common.afternoon') }}
      </b-col>
    </b-row>
    <b-row
      v-for="workingDay in workingDays"
      :key="workingDay.id"
      class="d-flex align-items-center border-light rounded"
    >
      <b-col
        cols="3"
        class="mr-1 py-1 bg-light-secondary"
      >
        <span>{{ $moment().isoWeekday(workingDay.day +1).locale($i18n.locale).format('dddd') | capitalize }}</span>
      </b-col>
      <b-col
        cols="4"
        class="d-flex align-items-center border-right"
      >
        <span
          v-if="workingDay.amStart || workingDay.amEnd"
          class="d-flex align-items-center"
        >
          <b-badge variant="light-success">
            <span>
              {{ workingDay.amStart }}:00
            </span>
            <span class="mx-50 font-small-2">
              {{ $t('common.to') | capitalize }}
            </span>
            <span>
              {{ workingDay.amEnd }}:00
            </span>
          </b-badge>
        </span>
        <span v-else>
          <b-badge variant="light-secondary">
            {{ $t('state.closed') }}
          </b-badge>
        </span>
      </b-col>

      <b-col
        cols="4"
        class="d-flex align-items-center"
      >
        <span
          v-if="workingDay.pmStart || workingDay.pmEnd"
          class="d-flex align-items-center"
        >
          <b-badge variant="light-success">

            <span>
              {{ workingDay.pmStart }}:00
            </span>
            <span class="mx-50 font-small-2">
              {{ $t('common.to') }}
            </span>
            <span>
              {{ workingDay.pmEnd }}:00
            </span>
          </b-badge>
        </span>
        <span v-else>
          <b-badge variant="light-secondary">
            {{ $t('state.closed') }}
          </b-badge>
        </span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

export default {
  components: {
  },
  props: {
    provider: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },
  data() {
    return {
      workingDays: [],
    }
  },
  mounted() {
    this.workingDays.push({ ...this.provider.monday, day: 0 })
    this.workingDays.push({ ...this.provider.tuesday, day: 1 })
    this.workingDays.push({ ...this.provider.wednesday, day: 2 })
    this.workingDays.push({ ...this.provider.thursday, day: 3 })
    this.workingDays.push({ ...this.provider.friday, day: 4 })
    this.workingDays.push({ ...this.provider.saturday, day: 5 })
    this.workingDays.push({ ...this.provider.sunday, day: 6 })
  },
}
</script>
