<template>
  <section class="row">
    <div style="height: inherit" class="ecommerce-application match-height col-12">
      <!--Sidebar-->
      <!--      <shop-left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />-->
      <!-- ECommerce Header -->
      <section id="ecommerce-header">
        <div class="row">
          <div class="col-sm-12">
            <div class="ecommerce-header-items">
              <div class="result-toggler">
                <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="21" @click="mqShallShowLeftSidebar = true" />
                <div class="search-results">{{ totalProducts }} {{ $tc('trip.shop.result_found', totalProducts) }}</div>
              </div>
              <div class="view-options d-flex">
                <!-- Sort Button -->
                <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" :text="sortBy.text" right variant="outline-primary">
                  <b-dropdown-item v-for="sortOption in sortByOptions" :key="sortOption.value" @click="sortBy = sortOption">
                    {{ sortOption.text }}
                  </b-dropdown-item>
                </b-dropdown>

                <!-- Item View Radio Button Group  -->
                <b-form-radio-group v-model="itemView" class="ml-1 list item-view-radio-group" buttons size="sm" button-variant="outline-primary">
                  <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                    <feather-icon :icon="option.icon" size="18" />
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Overlay -->
      <div class="body-content-overlay" />

      <!-- Searchbar -->
      <div class="ecommerce-searchbar mt-1">
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input v-model="filters.q" :placeholder="$t('action.search')" class="search-product" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <!-- Products -->
      <div class="row">
        <div class="col-sm-12">
          <section :class="itemView">
            <b-card v-for="product in products" :key="product.id" class="ecommerce-card" no-body>
              <div class="item-img text-center pt-0">
                <b-link
                  :to="{
                    name: 'shop-service-details',
                    params: {
                      product_id: product.id,
                      provider_id: '1',
                      trip_id: $route.params.trip_id,
                      event_id: $route.params.event_id,
                    },
                  }"
                >
                  <b-img :alt="`${product.name}-${product.id}`" class="card-img-top" :src="product.image" />
                </b-link>
                <b-badge class="badge-price" variant="primary">
                  <span v-if="product.price" class="font-medium-3">{{ product.price }} {{ $store.state.appConfig.currency }}</span>
                  <span v-else>{{ $t('trip.shop.price_on_quote') }}</span>
                </b-badge>
              </div>

              <!-- Product Details -->
              <b-card-body>
                <h6 class="item-name">
                  <b-link
                    class="text-body"
                    :to="{
                      name: 'shop-service-details',
                      params: {
                        product_id: product.id,
                        provider_id: product.provider.id,
                        trip_id: $route.params.trip_id,
                        event_id: $routeparams.event_id,
                      },
                    }"
                  >
                    {{ product.name | trans }}
                  </b-link>
                  <b-card-text class="item-company"> {{ $t('common.by') }} {{ product.provider.name }} </b-card-text>
                </h6>
                <div class="col-12 px-0 pt-1">
                  <font-awesome-icon icon="users" style="width: 25px" />
                  <span class="mx-50">
                    {{ product.passengers }}
                    {{ $tc('trip.event.passenger_number', product.passengers) }}
                  </span>
                </div>
                <div class="col-12 px-0 pt-50">
                  <font-awesome-icon icon="suitcase" style="width: 25px" />
                  <span class="mx-50">
                    {{ product.luggages }}
                    {{ $tc('trip.event.luggage_number', product.luggages, product.luggages) }}
                  </span>
                </div>
                <div class="col-12 px-0 pt-50">
                  <font-awesome-icon icon="tachometer-alt" style="width: 25px" />
                  <span class="mx-50">{{ product.speed }} km/h </span>
                </div>
                <b-card-text class="pt-1">
                  <div>
                    {{ $t('trip.shop.departure_from') }} <span class="font-weight-bold">{{ product.departure }}</span>
                  </div>
                  <div>
                    {{ $t('trip.shop.arrival_to') }} <span class="font-weight-bold">{{ product.arrival }}</span>
                  </div>
                </b-card-text>
                <b-card-text class="font-italic">
                  <span>
                    {{ $t('trip.shop.helicopter.proposed_by') }}
                  </span>
                  <span>
                    {{ product.provider.name }}
                  </span>
                  <b-link
                    class="company-name"
                    :to="{
                      name: 'provider-details',
                      params: {
                        provider_id: providerEntity.provider.id,
                        trip_id: $route.params.trip_id,
                        step_id: $route.params.step_id,
                        event_id: $route.params.event_id,
                        airport_id: $route.params.airport_id,
                      },
                    }"
                  >
                    {{ product.provider.name }}
                  </b-link>
                </b-card-text>
              </b-card-body>

              <!-- Product Actions -->
              <div class="item-options text-center">
                <div class="item-wrapper">
                  <div class="item-cost">
                    <h4 class="item-price">${{ product.price }}</h4>
                  </div>
                </div>
                <b-button tag="a" class="btn-cart" @click="handleCartActionClick(product)">
                  <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                  <span>{{ product.isInCart ? $t('trip.shop.book_flight') : $t('trip.shop.helicopter.action.select') }}</span>
                </b-button>
              </div>
            </b-card>
          </section>
        </div>
      </div>

      <!-- Pagination -->
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="totalProducts"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
      <section v-show="checkIsInCart(products)">
        <b-row>
          <b-col cols="12" class="d-flex justify-content-end">
            {{ products[0].provider.id }}
            <b-button
              tag="a"
              class="btn-cart"
              :to="{
                name: 'shop-checkout-event',
                params: {
                  trip_id: this.$route.params.trip_id,
                  event_id: this.$route.params.event_id,
                  provider_id: products[0].provider.id,
                },
              }"
            >
              <span>{{ $tc('trip.shop.helicopter.action.transfer_selected_next', products.length) }}</span>
              <feather-icon icon="ChevronRightIcon" class="ml-50" />
            </b-button>
          </b-col>
        </b-row>
      </section>
    </div>
  </section>
</template>

<script>
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

export default {
  name: 'ShopTransfer',

  data() {
    return {
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      sortBy: { text: 'Featured', value: 'featured' },
      sortByOptions: [
        { text: 'Lowest', value: 'price-asc' },
        { text: 'Highest', value: 'price-desc' },
      ],
      totalProducts: null,
      products: [],
      filterOptions: {
        engineType: ['Double rotor', 'One rotor'],
        brands: ['Total', 'PlaneLand', 'AirForce one'],
      },
      filters: {
        q: '',
        categories: [],
        brands: [],
        engine: '',
        page: 1,
        perPage: 9,
        perType: '',
      },
    }
  },
  computed: {
    allFilters() {
      return `${this.filters.currentPage}|${this.filters.q}|${this.sortBy}|`
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.fetchProducts()
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchProducts()
  },
  methods: {
    checkIsInCart(products) {
      return products.some(product => product.isInCart)
    },
    fetchProducts() {
      const params = {
        q: this.filters.q,
        sortBy: this.sortBy.value,
        page: this.filters.page,
        perPage: this.filters.perPage,
        perType: 'transfer',
      }
      this.$store
        .dispatch('shop/fetchProducts', params)
        .then(response => {
          const { products, total } = response.data
          this.totalProducts = total
          this.products = products
        })
        .catch(() => {
          this.toaster(this.$t('api.alert.trip.cannot_fetch'), 'danger', 'AlertTriangleIcon')
        })
    },
    handleCartActionClick(product) {
      if (product.isInCart) {
        this.$router.push({
          name: 'shop-checkout-event',
          params: {
            trip_id: this.$route.params.trip_id,
            event_id: this.$route.params.event_id,
            provider_id: this.products[0].provider.id,
          },
        })
      } else {
        this.addProductInCart(product.id).then(() => {
          // eslint-disable-next-line no-param-reassign
          product.isInCart = true
        })
      }
    },
    addProductInCart(productId) {
      return this.$store.dispatch('shop/addProductInCart', { productId })
    },
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    return {
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>
.badge-price {
  position: absolute;
  right: 8px;
  top: 8px;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
