<template>
  <b-card>
    <b-card-title class="text-center">
      {{ $t('provider.informations') }}
    </b-card-title>
    <b-card-text>
      <span class="font-weight-bold text-capitalize">
        {{ $t('organization.main_informations.contact_name') }} :
      </span>
      <span>
        {{ provider.emergencyContactLastName }} {{ provider.emergencyContactFirstName }}
      </span>
    </b-card-text>
    <b-card-text>
      <span class="font-weight-bold text-capitalize">
        {{ $t('organization.main_informations.contact_email') }} :
      </span>
      <span>
        {{ provider.emergencyContactEmail }}
      </span>
    </b-card-text>
    <b-card-text>
      <span class="font-weight-bold text-capitalize">
        {{ $t('organization.main_informations.contact_tel') }} :
      </span>
      <span>
        {{ provider.emergencyContactPhone }}
      </span>
    </b-card-text>
    <b-card-text>
      <span class="font-weight-bold">{{ $t('common.address') }} : </span>
      <span>
        {{ provider.defaultAddress.address }}, {{ provider.defaultAddress.postalCode }} {{ provider.defaultAddress.city }}, {{ provider.defaultAddress.countryName }}
      </span>
    </b-card-text>
    <b-card-text>
      <div class="font-weight-bold">{{ $t('organization.main_informations.typologies') }} :</div>
      <ul>
        <li v-for="typology in provider.typologies" :key="typology.id">
          {{ typology.name | trans }}
        </li>
      </ul>
    </b-card-text>
    <b-card-text>
      <div class="font-weight-bold">{{ $t('organization.airports') }} :</div>
      <ul>
        <li v-for="airport in provider.airports" :key="airport.icaoCode">
          {{ airport.airport.icaoCode }} - {{ airport.airport.name }}
        </li>
      </ul>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: 'ProviderProfileInformations',

  props: {
    provider: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
}
</script>
