<template>
  <b-card v-if="products.length" id="pricing-plan">
    <!-- title text and switch button -->
    <div class="text-center pb-2">
      <h1 class="mt-50">
        {{ $t('trip.shop.mandatory_packages') }}
      </h1>
      <p class="d-inline">
        {{ $t('trip.shop.mandatory_packages_notice') }}
      </p>
    </div>

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col cols="12">
        <b-row class="match-height px-xl-5">
          <b-col v-for="(productEntity, index) in products" :key="index" md="6" xl="4">
            <b-card class="popular" align="center">
              <div class="d-flex flex-column h-100">
                <h3 class="d-flex align-items-center justify-content-center">
                  <b-form-radio-group v-model="mandatorySelectedIndex" button-variant="outline-primary">
                    <b-form-radio :value="index">
                      <h3>{{ productEntity.product.name | trans }}</h3>
                    </b-form-radio>
                  </b-form-radio-group>
                </h3>

                <div class="annual-plan d-flex align-items-center justify-content-center">
                  <div v-if="productEntity.product.priceExcludingTaxes" class="mt-1 pricing-basic-value font-weight-bolder text-primary">
                    <span>{{ $t('trip.cost.total_before_tax') }} : </span>
                    <span>{{ productEntity.product.priceExcludingTaxes | priceFormat }}</span>
                  </div>
                  <div v-else class="mt-1 justify-content-center">
                    <span class="pricing-basic-value font-weight-bolder text-primary">Price on quote</span>
                  </div>
                </div>

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item v-for="variant in productEntity.product.variants" :key="variant.id">
                    {{ variant.productName | trans }}
                  </b-list-group-item>
                </b-list-group>

                <div v-if="mandatorySelectedIndex === index || productEntity.selected" class="mt-auto pt-2">
                  <b-button v-if="!productEntity.selected" class="btn-cart" @click="patchCart(index)">
                    <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                    <span>{{ $t('trip.shop.add_to_cart') }}</span>
                  </b-button>

                  <b-button v-else class="btn-cart" :to="{ name: 'shop-checkout', params: $route.params }">
                    <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                    <span>{{ $t('trip.shop.action.view_in_cart') }}</span>
                  </b-button>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { fetchMandatoryPackageRequest } from '@/request/globalApi/requests/apiRequests'
import Cart from '@/models/Cart'

export default {
  name: 'MandatoryPackage',

  data() {
    return {
      products: [],
      mandatorySelectedIndex: null,
      param: {
        page: 1,
        getAllItems: true,
        nameOrder: 'asc',
        searchQuery: null,
        categoriesId: [],
      },
      tripData: {},
      tripLeg: null,
      cart: new Cart(),
    }
  },
  mounted() {
    this.APIFetchMandatoryPackage()
  },
  methods: {
    APIFetchMandatoryPackage() {
      const organizationId = Number(this.$route.params.provider_id)
      const tripId = this.$route.params.event_id
      fetchMandatoryPackageRequest(tripId, organizationId, this.param)
        .then(r => {
          const { products } = r.data
          this.products = products
          this.mandatorySelectedIndex = products.findIndex(mp => mp.selected)
        })
        .catch(() => {
          this.products = []
        })
    },
    patchCart(index) {
      const tripStepId = this.$route.params.event_id
      this.cart.patchMandatoryPackage(tripStepId, this.products[index].product.mainVariantId).then(() => {
        this.products = this.products.map(product => ({ ...product, selected: false }))
        this.products[index].selected = true
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
.pricing-basic-value {
  font-size: 1.2rem !important;
}
</style>
